import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Jen from "../components/Jen"
import Mike from "../components/Mike"
import Jane from "../components/Jane"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/free-regular-svg-icons"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Container className="my-4">
      <h1 className="text-primary">About</h1>
      <p className="lead">
        <p>
          Waite Psychology is a family-run educational psychology service, initially set up in 2018 by Dr. Jennifer Waite (Educational and Child Psychologist) and joined by her husband, Dr. Michael Waite (Educational and Child Psychologist), in 2020.
        </p>
      </p>
      <Container>
        <Row>
          <Col lg>
            <Jen />
          </Col>
          <Col lg={8}>
            <h2 className="text-secondary">Dr. Jennifer Waite</h2>
            <p>
              Jen received her Doctorate in Child and Educational Psychology from The University of Manchester, and has delivered educational psychology services within the local authority and private sector for over 10 years.
            </p>
            <p>
              Since setting up Waite Psychology, Jen continues to provide a range of applied psychology services to educational settings across the North West, and associate support (where she can) to local authorities. Alongside running Waite Psychology, Jen is also an active board member and training-coordinator at Link Cooperative.
            </p>
            <p>
              Prior to setting up Waite Psychology, Jen was working as a Senior Educational Psychologist with a nurture specialism in the private sector. Prior to completing her professional training, Jen qualified with a first-class honours degree in Psychology in Education from Lancaster University, and worked as an Assistant Educational Psychologist for a local authority.
            </p>
            <p>
              Underpinning all of Jen's work is a consultative approach that applies solution-focused practice to joint problem solving with staff and parents, to find positive ways forward for children and young people. Jen also continues to have interests within social and emotional development, and is an Attachment Aware and Trauma Informed (AATI) specialist. It is within Jen's AATI practice that her passion emanates in empowering whole school policy and practice, that instills skills and confidence in school staff, to support children and young people to grow within their emotional security in school, to access learning opportunities and, most importantly, continue their education.
            </p>
            <Container>
              <Row>
                <Col xl>
                  <p className="text-primary text-center"><a href="../../Profile_Child_Jen.pdf" download><FontAwesomeIcon icon={faFilePdf} className="mr-2"/>Jen's Profile <br className="d-block d-sm-none"/>(child's version)</a></p>
                </Col>
                <Col xl>
                  <p className="text-primary text-center"><a href="../../Profile_Jen.pdf" download><FontAwesomeIcon icon={faFilePdf} className="mr-2"/>Jen's Profile <br className="d-block d-sm-none"/>(young person's version)</a></p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col lg>
            <Mike/>
          </Col>
          <Col lg={8}>
            <h2 className="text-primary">Dr. Michael Waite</h2>
            <p>
              Mike received his Doctorate in Child and Educational Psychology from The University of Manchester in 2020. He has delivered educational psychology services for 5 years as either a Trainee Educational Psychologist or as an Assistant Educational Psychologist within the local authority and private sector.
            </p>
            <p>
              Prior to working within educational psychology services, Mike was working in a therapeutic capacity, delivering support services directly to children and young people. Mike's training within therapeutic practice was a change in course of career for him, which evolved exponentially into acquiring a Masters in Psychology from Edge Hill University, qualifying with a Distinction, and his recent Doctorate and current role as an educational psychologist. Having come into educational psychology later in his career, Mike has brought a wealth of knowledge and skills from the corporate world of finance and business management, which provides additional support for the 'behind the scenes' running of Waite Psychology (a bonus for us as a family business!).
            </p>
            <p>
              Mike has a particular interest in early years development and within the field of adolescent social, emotional and mental health. He is passionate about effecting positive changes for children and young people through applications of evidence-based interventions and consultation models of practice. Mike's support to schools is underpinned by his therapeutic skills and philosophies on child-centred practice, that aims to ensure children and young people's voices play a key role in defining their needs, and in devising solutions to best to support them.
            </p>
            <Container>
              <Row>
                <Col xl>
                  <p className="text-primary text-center"><a href="../../Profile_Child_Mike.pdf" download><FontAwesomeIcon icon={faFilePdf} className="mr-2"/>Mike's Profile <br className="d-block d-sm-none"/>(child's version)</a></p>
                </Col>
                <Col xl>
                  <p className="text-primary text-center"><a href="../../Profile_Mike.pdf" download><FontAwesomeIcon icon={faFilePdf} className="mr-2"/>Mike's Profile <br className="d-block d-sm-none"/>(young person's version)</a></p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col lg>
            <Jane/>
          </Col>
          <Col lg={8}>
            <h2 className="text-secondary">Jane Heyworth</h2>
            <p>
              Jane has recently joined the team following a 13 year career as a school bursar.
            </p>
            <p>
              Jane has the pivotal role of supporting Jen with business development and will be managing the day to day running of the office and finances, whilst supporting the team as a whole.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default AboutPage
